import { apikeyBugsnagProd } from "../index";
import { IEnvConfig } from "@aspen/model";

const staticHost = "https://static.aspendigital.co/";
export const prodConfig: IEnvConfig = {
  env: "prod",
  host: {
    // dev: "http://8.219.137.127:19090/",
    dev: "https://api2.aspendigital.co/",
    prod: "https://api2.aspendigital.co/"
  },
  klineChartUrl: `${staticHost}klinechart/index.html`,
  tvChartUrl: "https://tradingview-nextjs-javascript-git-master-aspendigital.vercel.app",
  websocket: {
    host: "wss://ws.aspendigital.co:8080"
  },
  apiKeyBugsnag: apikeyBugsnagProd,
  webDomain: "https://portal.aspendigital.co",
  appOnlyDomain: "https://apponly.aspendigital.co",
  domainUriPrefix: "https://aspen.page.link",
  fallbackUrl: {
    android: "https://play.google.com/store/apps/details?id=com.prod.aspen",
    ios: "https://apps.apple.com/app/id1623978383"
  },
  packageName: {
    android: "com.prod.aspen",
    ios: "org.evg.prod.aspen"
  },
  // TODO 线上android versionCode待打包提审后修改下～
  minimumVersion: {
    android: "50",
    ios: "1.3.0"
  },
  operationServer: "https://api.aoperation.aspendigital.co/",
  saasServer: "https://api.dev-asportal.co/"
};
