import apikeyGa from "../constant/ga";
import apikeyGtm from "../constant/gtm";
import { devConfig } from "./host_dev";
import { betaConfig } from "./host_beta";
import { prodConfig } from "./host_prod";
import { preConfig } from "./host_pre";
import { DOWNLOAD_PATH } from "../constant";

let config = betaConfig;
if (process.env.HOST_ENV == "dev" || process.env.NEXT_PUBLIC_HOST_ENV == "dev") {
  config = devConfig;
}
if (process.env.HOST_ENV == "beta" || process.env.NEXT_PUBLIC_HOST_ENV == "beta") {
  // @ts-ignore
  config = betaConfig;
}
if (process.env.HOST_ENV == "pre" || process.env.NEXT_PUBLIC_HOST_ENV == "pre") {
  config = preConfig;
}
if (process.env.HOST_ENV == "prod" || process.env.NEXT_PUBLIC_HOST_ENV == "prod") {
  config = prodConfig;
}

/**
 * 本地调试在此修改调用不同环境的接口
 * Note: 注意环境本地会以ip+port访问，如果各环境账号密码一致，注意别误操作生产数据
 */

if (process.env.NODE_ENV != "production") {
  config = betaConfig;
}

let {
  klineChartUrl,
  tvChartUrl,
  env,
  host,
  apiKeyBugsnag,
  fallbackUrl,
  packageName,
  domainUriPrefix,
  webDomain,
  appOnlyDomain,
  operationServer,
  saasServer
} = config;

let websocket = {
  host: config.websocket.host,
  stage:
    typeof window !== "undefined" && window.location.href.includes("aspendigital.co")
      ? "RELEASE"
      : "TEST",
  appCode: "c022929d0e1f4b0d8eace9cb9934bf0d",
  registerPath: "/api/register",
  unregisterPath: "/api/unregister"
};

//web端现在页面
const webDownload = `${webDomain + DOWNLOAD_PATH}?source=web`;
//apponly端下载页面
const appOnlyDownload = `${appOnlyDomain + DOWNLOAD_PATH}?source=web`;

export {
  websocket,
  host,
  klineChartUrl,
  tvChartUrl,
  apikeyGa,
  apikeyGtm,
  env,
  apiKeyBugsnag,
  fallbackUrl,
  packageName,
  domainUriPrefix,
  webDomain,
  operationServer,
  saasServer,
  webDownload,
  appOnlyDownload
};
